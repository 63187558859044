.form {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.input_field {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input {
  display: flex;
  width: 250px;
  height: 46px;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  font-weight: 400;

  border: 1px solid var(--black-txt);
  background: var(--white-text);
}

.input_question {
  height: 140px;
  resize: none;
}

.btn {
  composes: buttonCommon from "../Button/Button.module.css";

  margin: 0 auto;

  background-color: var(--yellow-accent);
  color: var(--white-text);
  border: none;
  width: 288px;
  transition: background-color 250ms var(--transition);
}

.btn:hover,
.btn:focus{
  cursor: pointer;
  background-color: var(--yellow-accent-hover);
}  

.btn:active{
  background-color: var(--yellow-accent-click);
}

.error {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--red-error);
}

.input_error {
  border-color: var(--red-error);
}

.formContainer {
  /* margin-top: 44px; */
  /* margin-left: auto;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: center; */
  align-items: center;
  width: auto;
  
  /* min-height: 473px; */
  background-color: #fff;
  box-shadow: 3px 8px 14px rgba(136, 198, 253, 0.19);
  /* border-radius: 20px; */
  padding: 40px 10px;
  background-image: url("../../images/callback//collback-bg-L.png");
  background-size: contain;
  background-position:bottom;
  background-repeat: no-repeat;
}

@media screen and (min-width: 320px) {
  .input {
    width: 288px;
  }

  .formContainer {
    width: 320px;
  }
}

@media screen and (min-width: 768px) {
  .form {
    margin-top: 40px;
  }

  .input_field {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 52px;
  }

  .input {
    width: 330px;
  }

  .btn {
    width: 189px;
  }

  .formContainer {
    width: 480px;
    min-height: 420px;
    /* border-radius: 40px; */
    padding: 28px 40px;
  }
}

@media screen and (min-width: 1440px) {
  /* .form {
        margin-top: 144px;
    } */
  .input_field {
    margin-left: 24px;
    margin-right: auto;
  }

  .btn {
    /* margin-left: 189px; */
    margin-right: auto;
  }
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;

  display: block;
  border: none;
  outline: none;
  z-index: 1;
  cursor: pointer;
  background-color: #fff;
  color: var(--black-txt);
  transition: color 250ms var(--transition);
}

.editButton:hover,
.editButton:focus {
  color: var(--yellow-accent-hover);
} 

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill:currentColor;
}

.iconCross{
  stroke: currentColor; 
}