.container{
  margin: 0 auto;
  width: 100%;
  padding: 8px 8px;
  /* padding: 0 20px; */
}

@media screen and (min-width: 320px) {
  .container {
    max-width: 320px;
    padding: 8px 16px;
  }
}
/* tablet screen */
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 8px 40px;
  } 
}
    
  /* desktop screen */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
    padding: 24px 80px;
  }
}


