.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 16px;
  border: 1px solid var(--transparent-click);
  background: #E2F7FE;
}

.textWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

/* .textItem {
  min-width: 130px;
} */

.mobWrap {
  display: flex;
  flex-direction: column;
  gap:8px;
  width: 100%;
  /* flex-direction: space-between; */
  /* align-items: center; */
  /* gap: 10px; */

}

.photosList {
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.img {
  width: 100px;
}

.bigImg {
  width: 210px;
  /* border-radius: 10%; */
  border-radius: 8px;
}

.bigPhotoWrapper {
  padding: 8px 8px;
  max-height: calc(100vh - 140px);
}

.rateWrap{
  /* margin-left: 50px;  */
  width: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.rate {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
  width: 100%;
}

.btn {
  width: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: var(--yellow-accent);
  color: var(--white-text);
  border: none;
  transition: background-color 250ms var(--transition);
}

.btn:hover,
.btn:active{
  background-color: var(--yellow-accent-click);
}

.date {
  display: flex;
  gap: 8px;
  background-color: #d8f0fe;
  border-radius: 16px;
  padding: 16px;
}

.icon {
  width: 24px;
  height: 24px;
}

.additional_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.iconBg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--white-text);
}

.iconFlag {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.iconHotel {
  width: 36px;
  height: 36px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.countryAndHotel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.description {
  box-sizing: border-box;
  width: auto;
}

.hotelName {
  text-wrap: wrap;
}

@media screen and (min-width: 320px) {
  .rate {
    justify-content: flex-start;
  }

  .btn {
    width: 250px;
  }

  .additional_info {
    flex-direction: row;
    gap: 0px;
  }

  .bigImg {
    width: 300px;
    padding: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 20px;
  }

  .textWrap {
    /* width: 100%; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .bigImg {
    width: 410px;
    padding: 0px;
  }

  .btn {
    width: 189px;
  }

  .countryAndHotel {
    flex-direction: row;
    gap: 0px;
  }
}

@media (min-width: 1440px) {
  .container {
    gap: 24px;
    padding: 30px;
  }

  .bigImg {
    width: 510px;
  }

  .bigPhotoWrapper {
    padding: 45px 35px;
  }
}