.title {
font-weight: 700;
font-size: 20px;
/* font-size: 16px; */
line-height: 1.36;

margin-left: auto;
margin-right: auto;
text-align: center;

color: var(--black-txt);
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 20px;    
    }     
}

@media screen and (min-width: 1440px) {
    .title {
        font-size: 28px;
    }
}
