.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 43, 43, 0.8);
    z-index: 12;
}

.modalWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 40px;
    max-height: 90%;
    padding: 12px;
}

.modalWrapper::-webkit-scrollbar { 
    width: 0; 
    background: transparent;
}

.crossBtn{
    display: block;
    position: fixed;
    padding: 0;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    z-index: 14;
    cursor: pointer;

    color: var(--black-txt);
    background-color: transparent;

    transition: color 250ms var(--transition);
}

.exit {
    margin-top: 16px;
    margin-right: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border: none;
    background-color: #fff;
    z-index: 2;
}

.cross:hover,
.cross:focus {
    fill: var(--yellow-accent);
    stroke: var(--yellow-accent);
}

.childrenWrap {
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.childrenWrap::-webkit-scrollbar {
    display: none;
}

.cross {
    fill: currentColor;
    stroke: currentColor;
}

@media screen and (min-width: 320px) {
    .modalWrapper {
        padding: 20px;
    }

    .exit {
        margin-top: 20px;
        margin-right: 20px;
        width: 16px;
        height: 16px;
    }
}

@media screen and (min-width: 768px) {
    .modalWrapper {
        padding: 44px;
    }

    .exit {
        width: 24px;
        height: 24px;
    }
}

