.logo {
    width: 68px;
    height: 42px;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 95px;
    height: 58px;
  }
}

@media screen and (min-width: 1440px) {
  .logo {
    width: 109px;
    height: 65px;
  }
}