.container {
  position: relative;
}

.burger {
  cursor: pointer;
}

@media (min-width: 768px)  {
  .burger {
    margin-right: 20px;
  }
}

@media (min-width: 1440px) {
  .burger {
    display: none;
  }
}

.icon{
  fill: white;
  width: 40px;
  height: 40px;
  transition: fill 250ms var(--transition);
}

.icon:hover{
  fill: var(--yellow-accent-hover);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.01);
}