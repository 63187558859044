.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 8px;
  border: 1px solid var(--transparent-click);
  background: #E2F7FE;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    padding: 13px;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

@media (min-width: 1440px) {
  .container {
    padding: 24px;
  }

  .item {
    min-width: 150px;
  }
}

.category {
  font-weight: 700;
}