.list {
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 24px;
}

.container {
    display: flex;
    flex-direction: column-reverse;
    align-items:flex-start;
    gap: 20px;
    padding: 16px;
    border: 1px solid var(--transparent-click);
    background: #E2F7FE;
}

@media (min-width: 768px) {
    .list {
        margin-top: 20px;
    }

    .container {
        flex-direction: row;
        align-items: center;
        padding: 20px;
    }
}

@media (min-width: 1440px) {
    .list {
        gap: 64px;
        margin-top: 40px;
    }

    .container {
        gap: 28px;
        padding: 30px;
    }
}

.date {
    display: flex;
    gap: 8px;
    background-color: #d8f0fe;
    border-radius: 16px;
    padding: 16px;
}

.icon {
    width: 24px;
    height: 24px;
}

