.btn {
  width: 64px;
  height: 64px;

  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: var(--white-text);
  border-radius: 50%;
  background-color: var(--blue-main);
 
  box-shadow: rgba(184, 224, 231, 0.35) 0px 5px 15px;
  bottom: 450px;
  right: 16px;
  /* opacity: 0; */
  /* transition: bottom .2s ease, opacity .2s ease; */
  transition: background-color 0.2s ease;
  /* display: none; */
  position: fixed;
  z-index: 10;
}

.btn:hover{
  background-color: #08b3ec;
}

.icon {
  width: 32px;
  height: 21px;
  fill: var(--white-text);
}

@media screen and (min-width: 768px) {
  .btn {
    width: 84px;
    height: 84px;
    right: 40px;
  }

  .icon {
    width: 43px;
    height: 27px;
  }
}

@media screen and (min-width: 1440px) {
  .btn {
    right: 190px;
    bottom: 600px;
  }
}

/* html {
    scroll-behavior: smooth;
} */

.btn_hide {
  display: none;
}




