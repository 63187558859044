.container {
    width: 100%;
    height: 90vh;
}

.spinner_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--yellow-accent);
}

.spinner_container span {
    position: relative;
    top: 0.63em;
    display: inline-block;
    text-transform: uppercase;
    opacity: 0;
    transform: rotateX(-90deg);
}

.let1 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.2s;
}

.let2 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.3s;
}

.let3 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.4s;
}

.let4 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.5s;

}

.let5 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.6s;
}

.let6 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.7s;
}

.let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 0.8s;
}

@keyframes drop {
    10% {
        opacity: 0.5;
    }

    20% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }

    80% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }

    90% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        top: 6.94em
    }
}