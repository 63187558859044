.container {
    display: none;
}

.btn {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.icon{
    fill: white;
    width: 24px;
    height: 24px;
    transition: fill 250ms var(--transition);
}

.icon:hover{
    fill: var(--yellow-accent-hover);
}

@media (min-width: 768px) {
    .container {
        display: flex;
        align-items: center;
        gap: 40px;
    }
}

@media (min-width: 1440px) {
    .container {
        gap: 20px;
    } 

    .icon {
        width: 28px;
        height: 28px;
    }
}





