.list {
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 768px) {
  .list {
    margin-top: 20px;
  }
}

@media (min-width: 1440px) {
  .list {
    gap: 64px;
    margin-top: 40px;
  }
}