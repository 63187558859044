.notFound_thumb {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 288px;
  margin-top: 28px;

  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.homeLink {
  margin-top: 40px;
  display: block;
  text-align: center;
  composes: buttonCommon from "../../components/Button/Button.module.css";
  composes: button_yellow from "../../components/Button/Button.module.css";
}

.img {
  display: flex;
  width: 288px;
  height: 227px;
  padding: 0.01px 0px 0.037px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

@media screen and (min-width: 768px) {
  .notFound_thumb   {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title {
    font-size: 20px;
    width: auto;
    margin-top: 40px;
  }

  .homeLink {
    margin-top: 40px;
  }

  .img {
    width: 450px;
    height: 355px;
    padding: 0.016px 0px 0.058px 0px;
  }
}

@media screen and (min-width: 1440px) {
  .notFound_thumb   {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title {
    margin-top: 52px;
    font-size: 28px;
  }

  .homeLink {
    margin-top: 52px;
    margin-left: auto;
    margin-right: auto;
  }

  .img {
    width: 845px;
    height: 666px;
    padding: 0.03px 0px 0.109px 0px;
  }
}
