.tourFeedbackForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap:20px; */
}

.input {
    composes: input from "../../global.css";
}

.inputWrapper {
    /* margin-top: 32px; */
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.regNav {
    margin-top: 16px;
    font-style: 16px;
}

.regLink {
    text-decoration: underline;
    color: var(--yellow-accent);
}

.regLink:hover {
    color: var (--yellow-accent-click); 
}

.error {
    color: red;
    font-size: 12px;
}

.input_error {
    border-color: var(--red-error);
}

.input_question {
    height: 140px;
    resize: none;
    font-family: Arial;
}

.label{
    display: inline-block;
    width: 240px;
}

@media screen and (min-width: 320px) {
    .label {
        width: 100%;
        display: inline;
    }
}

@media screen and (min-width: 768px) {
    .inputWrapper {
        /* margin-top: 40px; */
        margin-bottom: 52px;
    }
}

.check {
    margin-right: 8px;
}