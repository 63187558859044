.container{
  /* max-width: 1440px; */
  margin: 0 auto;
  width: 100%;
  /* padding: 0 20px; */
  /* @media screen and (min-width: 768px) {
    max-width: 768px;
  } */
/*  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }*/
}



.hero_image {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../images/hero/hero_bg_S@1x.webp");
}

@media only screen and (min-device-pixel-ratio: 2), 
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    .hero_image {
      background-image: url("../../images/hero/hero_bg_S@2x.webp");
    }   
}

@media only screen and (min-width: 768px) {
  /* max-width: 768px; */
  .hero_image {
    background-image: url("../../images/hero/hero_bg_M@1x.webp");
  }
}

@media only screen and (min-device-pixel-ratio: 2) and (min-width: 768px),
  only screen  and (min-resolution: 192dpi) and (min-width: 768px),
  only screen  and (min-resolution: 2dppx) and (min-width: 768px) {
    .hero_image {
      background-image: url("../../images/hero/hero_bg_M@2x.webp");
    }
}  


@media only screen and (min-width: 1440px) {
  /* max-width: 1440px; */
  .hero_image {
    background-image: url("../../images/hero/hero_bg_L@1x.webp");
  }
}

@media only screen and (min-width: 1440px) and (min-device-pixel-ratio: 2),
  only screen  and (min-resolution: 192dpi) and (min-width: 1440px),
  only screen  and (min-resolution: 2dppx) and (min-width: 1440px) {
    .hero_image {
      background-image: url("../../images/hero/hero_bg_L@2x.webp");
    }
  }


