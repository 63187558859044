.container {
  width: 100%;
  margin: 0 auto;
  padding-top: 8px;
  padding-bottom: 24px;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap:24px;
}

.title {
  text-align: center;
  font-size: 16px;
  width: 250px;
}

.bgWrapper {
  background-image: url("../../images/callback/collback-bg-S.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.img {
  width: 519px;
  height: 614px;
  display: none;
}

@media screen and (min-width: 320px) {
  .container {
    width: 288px;
    padding-top: 28px;
    padding-bottom: 60px;
  }

  .title {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 688px;
    padding-top: 64px;
    padding-bottom: 80px;
  }

  .title {
    font-size: 20px;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
  }

  .bgWrapper {
    background-image: url("../../images/callback/collback-bg-L.png");
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 1086px;
    padding-top: 52px;
    padding-bottom: 100px;
    position: relative;
  }

  .title {
    font-size: 28px;
    width: 846px;
    margin-right: 108px;
    margin-top: 0;
  }

  .img {
    display: block;
  }
}