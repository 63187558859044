/* ------------------INPUTS------------------ */

.input {
  display: flex;
  width: 228px;
  height: 46px;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid var(--black-txt);
  background: var(--white-text);
}

@media screen and (min-width: 320px) {
  .input {
    width: 288px;
  }
}

@media screen and (min-width: 768px) {
  .input {
    width: 519px;
  }
}
