:root {
    --yellow-accent: #FEAE00;
    --yellow-accent-hover: #FBBF3C;
    --yellow-accent-click:#D6A12E;
    --white-text: #FFFFFF;
    --black-txt:#1F1F1F;
    --gray-txt: #5E5E5E;
    --blue-main: #00AAE1;
    --accent-disable: #D7C7A5;
    --transparent-hover:rgba(255, 255, 255, 0.24);
    --transparent-click: #979797;
    --red-error: #D62525;
    --transition: cubic-bezier(0.4, 0, 0.2, 1);
}