.list {
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
}

.input {
  composes: input from "../../global.css"; 
}

@media (min-width: 1440px) {
  .list {
    margin-top: 40px;
  }

  .input {
    width: 370px;
    padding: 14px;
  }
}

.inputWrapper {
  margin-top: 20px; 
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
  gap: 10px;
}


