
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px 60px 16px;
  background-image: 
  url("../../images/search/rectangle.png"),
  url("../../images/search/rectangle.png");
  background-size: 159px, 159px;
  background-position: 25px 60px, 25px bottom;
  background-repeat: no-repeat; 
}

.input {
  composes: input from "../../global.css";  
}

.textArea {
  composes: input from "../../global.css";
  min-height: 250px;
  width: 250px;
}

.select {
  display: flex;
  width: 250px;
  height: 46px;
  padding: 12px;
  align-items: flex-start;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid var(--black-txt);
  background: var(--white-text);
}

.formWrapper {
  margin: 0;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}


.error {
  color: red;
  font-size: 12px;
}

.input_error {
  border-color: var(--red-error);
}

.photoList{
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.photoWrap {
  position: relative;
  width: 68px;
}

.photo {
  width: 100%;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue-main);
  background-color: var(--white-text);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: color 250ms var(--transition);
}

.close:hover {
  background-color: rgba(245, 240, 193);
  color: var(--yellow-accent);
}

.inputFiles {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.labelFiles {
  display:block;
  padding: 7px 14px;
  margin: 0;
  width: 200px;
  height: 32px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;

  border: 1px solid var(--blue-main);
  background-color: transparent;
  color: var(--blue-main);
  transition: background-color 250ms var(--transition),
    color 250ms var(--transition),
    border 250ms var(--transition);
}

.labelFiles:hover,
.labelFiles:focus {
  cursor: pointer;
  background-color: rgba(245, 240, 193, 0.767);
  color: var(--yellow-accent);
  border: 1px solid var(--yellow-accent);
}

.labelFiles:active {
  background-color: var(--yellow-accent-click);
  color: var(--yellow-accent);
}

.icon {
  fill: currentColor;
  width: 8px;
}

@media screen and (min-width: 320px) {
  .textArea {
    width: 288px;
  }

  .select {
    width: 288px;
  }

  .photoWrap {
    width: 88px;
  }

  .close {
    width: 20px;
    height: 20px;
  }

  .icon {
    width: 12px;
  }
}

@media screen and (min-width: 768px) {
  .form {
    padding: 60px 40px 100px 60px;
    background-image: 
    url("../../images/hot/tour-hot1x.png"),
    url("../../images/hot/dream-hot1x.png"),
    url("../../images/search/rectangle.png"),
    url("../../images/search/rectangle.png");
    background-size: 493px, 633px, 159px, 159px;
    background-position:
    40px 60px,
    70% 68%,
    93% 60px,
    40px 50%;
    background-repeat: 
      no-repeat;
  }

  .textArea {
    width: 519px;
  }

  .formWrapper {
    margin-top: 40px;
    margin-bottom: 52px;
  }

  .labelFiles {
    margin: 0;
  }

  .photoList {
    gap: 16px;
  }

  .photoWrap {
    width: 180px;
  }

  .close {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .form {
    padding: 80px 282px 100px 282px;
    background-image: 
    url("../../images/hot/tour-hot1x.png"),
    url("../../images/hot/dream-reversed1x.png"),
    url("../../images/search/rectangle.png"),
    url("../../images/search/rectangle.png");
    background-size: 493px, 130px, 159px, 159px;
    background-position:
    160px 80px,
    80% 55%,
    230px 50%,
    68% 80px;
  }

  .input {
    width: 750px;
    padding: 14px;
  }

  .textArea {
    width: 750px;
    padding: 14px;
  }

  .labelFiles {
    width: 240px;
  }


  .photoWrap {
    width: 280px;
  }
}