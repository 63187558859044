.footer {
    background-color: var(--blue-main);
}

.logo {
    display: none;
}

.copyright {
    color: var(--white-text);
    font-size: 12px;
    font-weight: 400;
    margin-top: 60px;
    margin-bottom: 52px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .footer {
        padding-top: 32px;
    }

    .copyright {
        margin-bottom: 32px;
    }
}

@media screen and (min-width: 1440px) {
    .logo {
        display: block;
        text-align: center;
        height: 204px;
        margin-right: auto;
        margin-left: auto;
    }
}