.buttonCommon {
  /* width: 189px; */
  padding-top: 7px;
  padding-bottom: 7px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 160px;
}

@media screen and (min-width: 320px) {
  .buttonCommon {
    width: 240px;
  }
}

@media screen and (min-width: 768px) {
  .buttonCommon {
    width: 189px;
  }
}

.button_yellow {
  composes: buttonCommon;

  background-color: var(--yellow-accent);
  color: var(--white-text);
  border: none;
  transition: background-color 250ms var(--transition);
}

.button_yellow:hover,
.button_yellow:active{
  background-color: var(--yellow-accent-click);
}