.userDataContainer {
    display: flex;
    gap: 32px;
    flex-direction: column;

    align-items: center;
}

@media screen and (min-width: 768px) {
    .userDataContainer  {
        flex-direction: row;
        align-items: flex-start;
        gap: 100px;
    }
}

.buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.button_white {
    display: flex;
    align-items: center;
    justify-content: center;
    composes: buttonCommon from "../../components/Button/Button.module.css";
    background-color: transparent;
    /* color: var(--white-text);
    border: 1px solid var(--white-text); */
    color: var(--yellow-accent);
    border: 1px solid var(--yellow-accent); 
    transition: background-color 250ms var(--transition);
}

.button_white:hover {
    ackground-color: rgba(245, 240, 193, 0.767);
}

.wrapper {
    margin-top: 24px;
}