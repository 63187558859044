.dropdown {
    width: 190px;
    position: absolute;
    top: 40px;
    right: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    background-color: var(--blue-main);
    z-index: 100;
}

@media (min-width: 768px) {
    .dropdown {
        right: 40px;
    }
}

.link {
    color: var(--white-text);
    font-weight: 600;
}

.link:hover{
    color: var(--yellow-accent-hover);
}

.active {
    color: var(--yellow-accent);
}

.btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--white-text);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: normal;
    width: 60px;
    padding-left: 0;
}

.btn:hover{
    color: var(--yellow-accent-hover);
}