.wrap {
    margin: 0;
    width: 80vw;
    max-height: calc(100vh - 200px);
}

.btnWrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.btn{
    width: 50%;
    margin-top: 32px;
    padding-top: 4px;
    padding-bottom: 4px;
    
    font-size: 12px;
    font-weight: 600;
    height: 54px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    text-transform: uppercase;
    border: 2px solid var(--blue-main);
    border-bottom: none;
    background-color: var(--white-text);
    color: var(--blue-main);
    transition: background-color 0.2s ease;
}

.btn:hover,
.btn:focus{
    background-color: #08b3ec;
    color: var(--white-text);
}

.btnActiv {
    background-color: var(--blue-main);
    color: var(--white-text);
}

.formWrap {
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 2px solid var(--blue-main);
    /* border-top: none; */
    
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    background-image: 
    url("../../images/search/rectangle.png"),
    url("../../images/search/rectangle.png");
    background-size: 152px, 152px;
    background-position: 100px 10px, 15px bottom;
    background-repeat: no-repeat; 
}

@media screen and (min-width: 480px) {
    .formWrap {
        padding: 32px 32px;
        border-radius: 10px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        background-image: 
        url("../../images/search/rectangle.png"),
        url("../../images/search/rectangle.png");
        background-size: 244px, 212px;
        background-position: 268px 16px, 44px bottom;
        background-repeat: no-repeat; 
    }
}

@media screen and (min-width: 620px) {
    .wrap {
        width: 480px;
    }
}

@media screen and (min-width: 768px) {
    .wrap {
        width: 644px;
    }

    .btn {
        /* width: 189px; */
        padding-top: 7px;
        padding-bottom: 7px;
        margin-top: 0;
    
        font-size: 16px;
        font-weight: 600;
        height: 54px;
    }
}

@media screen and (min-width: 1440px) {
    .wrap {
        width: 840px;
    }

    .formWrap {
        background-image: 
        url("../../images/search/rectangle.png"),
        url("../../images/search/rectangle.png");
        background-size: 244px, 212px;
        background-position: 408px 16px, 84px bottom;
        background-repeat: no-repeat; 
    }
}