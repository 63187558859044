.title {
    color: var(--white-text);
    font-weight: 600;
    font-size: 20px;
}

.phone_icon {
    fill: var(--white-text);
    width: 22px;
    height: 22px;
}

.net_icon {
    cursor: pointer;
    fill: var(--white-text);
    width: 30px;
    height: 30px;
    transition: color 250ms var(--transition);
}

.net_icon:hover{
    fill: var(--yellow-accent-hover);
}

.contacts_data {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contacts_data:not(:first-child) {
    margin-top: 40px;
}

.list_with_image {
    display: flex;
    align-items: center;
    gap: 24px;
}

.list_net_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.contact_list {
    display: flex;
    flex-direction: column;
    gap:20px;
    padding-left: 0;
    margin: 0;
}

.contact_item {
    color: var(--white-text);
    font-weight: 400;
    font-style: 16px;
}

.shedule_and_address {
    margin-top: 40px;
}

@media screen and (min-width: 768px) {
    .wrapper {
    display: flex;
    gap: 155px;
    justify-content: center;
    }

    .shedule_and_address {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 0px;
    }
}

@media screen and (min-width: 1440px) {
    .wrapper {
        gap: 146px;
    }

    .title {
        font-size: 28px;
    }

    .contacts_data {
        gap: 28px;
    }

    .contacts_data:not(:first-child) {
        margin-top: 0px;
    }

    .list_with_image {
        gap: 28px;
    }

    .list_net_icon {
        gap: 28px;
    }

    .shedule_and_address {
        flex-direction: row-reverse;
        align-items: stretch;
        gap:146px;
    }
}






